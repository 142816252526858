
import React, { useState, useEffect, useMemo, useRef } from 'react';
// import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';
import * as gmAPI from "../utils/GMillionAPI";

const PRE_ACCESS_TOKEN_EXPIRE_PERIOD = 5 * 60000;   //60000 = 1min
// const PRE_ACCESS_TOKEN_EXPIRE_PERIOD = (23 * 60 * 60000) + (59 * 60000); 

export const useCooker = () => {
    const cookies = new Cookies();
    // const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'preAccessTokenExp'
        // , 'refreshToken', 'email'
    // ]);
    // const cookiesChanged = useRef(null);
    const [emailState, setEmailState] = useState(localStorage.getItem("email"));

    const accessToken = () => {
        // return cookies.accessToken;
        return cookies.get('accessToken');
    }

    function setAccessToken(accessToken, accessToken_expiration_time){
        const accessTokenExpTime = new Date(accessToken_expiration_time);
        cookies.set('accessToken', accessToken, { path: '/', secure: (process.env.REACT_APP_COOKIE_SECURE === 'true'), expires: accessTokenExpTime});
        cookies.get('accessToken'); //do it here so make it sure to get new coookie value ?
        // console.log('cookies test accesstoken', cookies.get('accessToken'));
        // setPreAccessTokenExpire(accessToken_expiration_time, accessTokenExpTime);
    }

    function removeAccessToken(){
        removeEmail();
        cookies.remove('accessToken', { path: '/'});
        cookies.get('accessToken');
        // removePreAccessTokenExpire();
    }

    function preAccessTokenExpire(){
        return cookies.get('preAccessTokenExp');
    }

    function setPreAccessTokenExpire(accessToken_expiration_time, accessTokenExpTime){
        const preAccessTokenExpTime = new Date(accessTokenExpTime.getTime() - PRE_ACCESS_TOKEN_EXPIRE_PERIOD);
        // console.log('accessTokenExpTime: ' + accessTokenExpTime);
        // console.log('preAccessTokenExpTime: ' + preAccessTokenExpTime);
        // console.log('Now: ' + new Date());
        cookies.set('preAccessTokenExp', 'preAccessTokenExp', { path: '/', secure: (process.env.REACT_APP_COOKIE_SECURE === 'true'), expires: preAccessTokenExpTime});
        cookies.get('preAccessTokenExp');
    }

    function removePreAccessTokenExpire(){
        cookies.remove('preAccessTokenExp');
        cookies.get('preAccessTokenExp');
    }

    const refreshToken = () => {
        const refreshToken = localStorage.getItem("refreshToken");
        return refreshToken;
        // return cookies.refreshToken;
    }
    
    function setRefreshToken(refreshToken, refreshToken_expiration_time){
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("refreshToken_expiration_time", refreshToken_expiration_time);
        // const refreshTokenExpTime = new Date(refreshToken_expiration_time);
        // setCookie('refreshToken', refreshToken, {
        //     path: '/',
        //     secure: true, // Secure flag for HTTPS
        //     // httpOnly: true, // HttpOnly flag //only on server
        //     expires: refreshTokenExpTime,
        // });
        // console.log('refreshToken setted', refreshToken);
    }

    function removeRefreshToken(){
        removeEmail();
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("refreshToken_expiration_time");
        // removeCookie('refreshToken');
    }
    
    const email = () => {
        const email = localStorage.getItem("email");
        return email;
        // if(emailState === '') return null;
        // return emailState;
    }

    function setEmail(value){
        // console.log('setEmailState');
        localStorage.setItem("email", value)
        setEmailState(value);
    }

    function removeEmail(){
        localStorage.removeItem("email")
        setEmailState('')
        // cookiesChanged.current();
        // removeCookie('email');
    }

    // function getEmail(){
    //     return localStorage.getItem("email");
    // }

    return{
        // cookies, setCookie, removeCookie,
        accessToken, setAccessToken, removeAccessToken,
        refreshToken, setRefreshToken, removeRefreshToken,
        email, setEmail, removeEmail, emailState,
        preAccessTokenExpire, setPreAccessTokenExpire, removePreAccessTokenExpire,
        // cookiesChanged,
    };
}








// let cookies, setCookie, removeCookie;
// let navigate;

// export function useCooker(){
//     [cookies, setCookie, removeCookie] = useCookies(['email', 'accessToken', 'refreshToken']);
//     navigate = useNavigate();

//     return [cookies, setCookie, removeCookie];
// }

// export const getCookies = () => {
//     return cookies;
// }

// export const setAccessToken = (accessToken, accessToken_expiration_time) => {
//     const accessTokenExpTime = new Date(accessToken_expiration_time)
//     setCookie('accessToken', accessToken, { path: '/', secure: true, expires: accessTokenExpTime});
// }

// export const setEmail = (email) => {
//     setCookie('email', email, { path: '/', secure: true});
// }

// export const setRefreshToken = (refreshToken, refreshToken_expiration_time) => {
//     const refreshTokenExpTime = new Date(refreshToken_expiration_time);
//     setCookie('refreshToken', refreshToken, {
//         path: '/',
//         secure: true, // Secure flag for HTTPS
//         // httpOnly: true, // HttpOnly flag //only on server
//         expires: refreshTokenExpTime,
//     });
// }

// export const removeAllAuthCookies = () => {
//     removeAccessToken();
//     removeRefreshToken();
//     removeEmail();

//     //todo send logout to server
// }

// export const removeAccessToken = () => {
//     removeCookie('accessToken');
// }

// export const removeRefreshToken = () => {
//     removeCookie('refreshToken');
// }

// export const removeEmail = () => {
//     removeCookie('email');
// }

// // Use RefreshToken to get new accessToken and renew itself, ill cause a redirect to login page on fail request
// export async function useRefreshToken(email){
//     try {
//         const response = await gmAPI.put(
//             '/auth', 
//             JSON.stringify({ 
//                 refreshtoken: cookies.refreshToken,
//             })
//         )

//         // data: {"uuid": decoded.uuid, accessToken, refreshToken, accessToken_expiration_time, refreshToken_expiration_time},
//         const {uuid, accessToken, refreshToken, accessToken_expiration_time, refreshToken_expiration_time} = response.data.data;

//         // // Set access token as a cookie
//         cookies.setAccessToken(accessToken, accessToken_expiration_time);
//         cookies.setEmail(email);

//         // Store the refresh token as an HttpOnly, secure cookie
//         cookies.setRefreshToken(refreshToken, refreshToken_expiration_time);

//     }catch(err){
//         console.log(err);

//         //Clear all auth token
//         removeAllAuthCookies();

//         //redirect to login page
//         navigate(`/login`);
//     }finally{

//     }
// }

// export async function isLoggedIn(){

//     const accessTokenRemain = cookies.accessToken?.expires - new Date();

//     //aysn reauth using refreshtoken when access not present or <3 mins to expire
//     if( (!cookies.accessToken || accessTokenRemain < (3 * 60 * 1000))
//         && cookies.refreshToken)
//     {
//         await this.useRefreshToken(cookies.email);    //on failed request will do redirect to login
//     }

//     return cookies.email && cookies.accessToken;
// }