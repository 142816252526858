import React, {useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "react-router-dom";

import './projects_v1.css';


export function Project(prop) {
    return(
        <Row className=''>
            <Col lg='4' className='info m-auto d-none d-lg-block'>
                <div>
                    <h1 className='target'>{prop.target}</h1>
                    <h2 className={prop.namecolor}>
                        {prop.name}
                    </h2>
                    <h2 className='gray'>
                        {prop.dev}
                    </h2>
                    <h2 className='gray'>
                        {prop.service}
                    </h2>
                    <br />
                </div>
            </Col>
            <Col lg='auto' className='timeline d-none d-lg-block'>
                <img className='timeline_dot' src='img/home/timeline_dot.png' />
                <div className={'theline ' + (prop.linepos || ' ')} />
            </Col>
            <Col xs='auto' lg='5' className='project_img m-auto py-2 position-relative'>
                <Link to={prop.link || '/coming_soon'}>
                    <img className='img-fluid d-block w-100' src={prop.img} />
                    {/* <img className='position-relative img-fluid d-block d-md-none' src={prop.img_mobile} /> */}
                    <div className='links position-absolute d-flex flex-column'
                        style={{
                            top: '27%',
                            right: '12%',
                            height: '100%',
                        }} 
                    >
                        <img className='position-relative' 
                            style={{
                                height: '25%',
                            }} 
                        src={prop.img_title} />
                        <div className={`mx-auto mt-1 d-inline-flex justify-content-center rounded-pill bg-${prop.namecolor}`}
                            style={{
                                maxHeight: '10%'
                            }}
                        >
                            {
                                prop.link && 
                                <Link to={prop.link} >
                                    <img className='img-fluid p-1 p-xxl-2 my-auto'
                                        style={{
                                            maxHeight: '100%',
                                        }} 
                                        src='/img/commons/icon_web_24.png' 
                                    />
                                </Link>
                            }
                            {
                                prop.link_ios && 
                                <Link to={prop.link_ios} >
                                    <img className='img-fluid p-1 p-xxl-2 my-auto'
                                        style={{
                                            maxHeight: '100%',
                                        }} 
                                        src='/img/commons/icon_ios_24.png' 
                                    />
                                </Link>
                            }
                            {
                                prop.link_android && 
                                <Link to={prop.link_android} >
                                    <img className='img-fluid p-1 p-xxl-2 my-auto'
                                        style={{
                                            maxHeight: '100%',
                                        }} 
                                        src='/img/commons/icon_android_24.png' 
                                    />
                                </Link>
                            }
                            
                        </div>
                    </div>
                </Link>
                {/* project_title_megam */}
            </Col>
            <Col xs='12' md='12' className='d-block d-lg-none mx-auto' style={{ maxWidth: "773px" }}>
                <div className=''>
                    <div className='d-flex justify-content-between'>
                        <h2 className='my-auto'>{prop.target}</h2>
                        <h2 className={'my-auto     ' + prop.namecolor}>
                            {prop.name}
                        </h2>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <h4 className='my-auto gray'>
                            {prop.dev}
                        </h4>
                        <h4 className='my-auto gray'>
                            {prop.service }
                        </h4>
                    </div>
                </div>
                <p />
            </Col>
        </Row>
    )
}

export default function Projects_v1(){
    //i18n
    const { t, i18n } = useTranslation('home/projects');

    return(
        <Container fluid className='projects mx-auto justify-content-center pt-1 pb-1' style={{backgroundImage: "url('/img/home/projects_bg.jpg')"}}>
            <br/>
            <Project 
                target={t("ilb.target")}
                name={t("ilb.name")}
                namecolor={t("ilb.namecolor")}
                dev={t("ilb.dev")}
                service={t("ilb.service")}
                img='/img/home/project_ilb.png'
                img_mobile='/img/home/project_ilb_mb.png'
                img_title='/img/home/project_title_ilb.png'
                link='https://iloveburger.gmillion.io'
                link_android='https://play.google.com/store/apps/details?id=io.gmillion.iloveburger'
                link_ios='https://apps.apple.com/th/app/id6450951515'
                linepos='start'
            />

            <Project 
                target={t("fog.target")}
                name={t("fog.name")}
                namecolor={t("fog.namecolor")}
                dev={t("fog.dev")}
                service={t("fog.service")}
                img='/img/home/project_fog.png'
                img_mobile='/img/home/project_fog_mb.png'
                img_title='/img/home/project_title_fog.png'
                link='https://fog.gmillion.io'
                link_android='https://play.google.com/store/apps/details?id=io.gmillion.foga'
                link_ios='https://apps.apple.com/th/app/id6670787265'
                
            />

            <Project 
                target={t("megam.target")}
                name={t("megam.name")}
                namecolor={t("megam.namecolor")}
                dev={t("megam.dev")}
                service={t("megam.service")}
                img='/img/home/project_megam.png'
                img_title='/img/home/project_title_megam.png'
                img_mobile='/img/home/project_megam_mb.png'
                link='https://megazombiem.gmillion.io/'
                link_android='/coming_soon'
                link_ios='/coming_soon'
                // linepos='end'
            />
            {/* <Project 
                target={t("retohub.target")}
                name={t("retohub.name")}
                namecolor={t("retohub.namecolor")}
                dev={t("retohub.dev")}
                service={t("retohub.service")}
                img='/img/home/project_retohub.png'
                img_mobile='/img/home/project_retohub_mb.png'
                // link='https://iloveburger.gmillion.io'
                // linepos='end'
            /> */}
            <Project 
                target={t("minegame.target")}
                name={t("minegame.name")}
                namecolor={t("minegame.namecolor")}
                dev={t("minegame.dev")}
                service={t("minegame.service")}
                img='/img/home/project_minegame.png'
                img_mobile='/img/home/project_minegame_mb.png'
                img_title='/img/home/project_title_minegame.png'
                link='/coming_soon'
                link_android='/coming_soon'
                link_ios='/coming_soon'
                linepos='end'
            />

            
            <br/>
        </Container>
    )
}