import React, {useEffect } from 'react';
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import "./home.css"
import Home_minestone from "./home_minestone.js"
import Projects_v1 from './projects_v1';


// import part1Image from './img/home/gmillion_office.svg';

// var p1BgImage = {
//     backgroundImage: `url(${part1Image})`
//  }
 

export default function Home(){
    //i18n
    const { t, i18n } = useTranslation('home/home');

    return(
        <div className={'home lang-' + i18n.language}>
            {/* <div className='part1' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/home/gmillion_office.svg'})` }}> */}
            <div className='lv1_grid' id='home_p1'>
                <img className='bg'  src='./img/commons/home_bg.jpg' alt='Smile Power'/>
                <div className='prop-holder'>
                    <img className='bg-prop p1' src='./img/home/home_bg01.jpg' alt=''/>
                    <img className='bg-prop p2' src='./img/home/home_bg02.jpg' alt=''/>
                    <img className='bg-prop p3' src='./img/home/home_bg03.jpg' alt=''/>
                </div>
                
                <div className='group3545 ' id='home_p1_content'>
                    <div className='text1 h1 transi_ease_in_out white ani_fade_in my-1'>G MILLION</div>
                    <div className='text2 h1 transi_ease_in_out black ani_fade_in mt-1 mb-2'>SMILE POWER</div>
                    <div className='d-none d-md-block'>
                        <div className={'text3 h3 textshadow transi_ease_in_out white ani_fade_in d-flex flex-row ' }>{t('P1_CONTEXT')} &nbsp;<div className='black'>{t('P1_CONTEXT2')}</div></div>
                    </div>
                    <div className='d-block d-md-none'>
                        <div className={'text3 ani_fade_in d-flex flex-row justify-content-center ' }>{t('P1_CONTEXT')} &nbsp;<div className='black'>{t('P1_CONTEXT2')}</div></div>
                    </div>
                    <div className=' ani_fade_in my-3' style={{display: 'inline-flex'}}>
                        <Link to="/coming_soon">
                            <button className={'white_box shadow25 ' } id='p1_b1'>{t('LEARN_MORE')}</button>
                        </Link>
                        {/* <Link to="/coming_soon">
                            <button className={'white_box ' } id='p1_b2'>{t('GET_IN_TOUCH')}</button>
                        </Link> */}
                    </div>
                </div> 
            </div>
            <div className='lv1_grid home_p2' id='home_p2' >
                <img className='group3557bg bg d-none d-md-block'  src='./img/home/home_part2_bg.jpg' alt='About G Million'/>
                <img className='group3557bg d-block d-md-none '  src='./img/home/home_part2_mobile_bg.jpg' alt='About G Million'/>
                <div className='group3557'>
                    <img id='img4575' className='img4575'  src='./img/home/group4575.png' />
                    <div className='group3557_content ani_fade_in mx-auto'>
                    <div><span className='h2 gray74'>ABOUT </span><span className='h2 blueee'>G MILLION</span></div>
                    <p></p>
                    <span className='h4'>
                        {t('P2_CONTEXT')}
                    </span>
                    <p></p>
                    <Link to="/coming_soon" style={{display: 'grid', justifyContent: 'left', alignItems: 'left', textAlign: 'left'}}>
                        <button className={'blue_box ' } id='p1_b1'>{t('SEE_MORE')}</button>
                    </Link>
                    </div>
                </div>
            </div>
            <div id='home_projects' />
            {/* <Home_p3 /> */}
            <Projects_v1 />
            <div className='lv1_grid' id='home_p4' >
                <div className='group3559 ani_fade_in my-5' id='group3559'>
                    <img className='imgglogo d-none d-md-block' src='./img/home/gmillion_logo.png' />
                    <br/>
                    {/* {t('CHANGE_YOUR_GAME')} */}
                    <div><span className='h2 black'>{t('home_p4_title1')}</span></div>
                    <div><span className='h2 black'>{t('home_p4_title2')}</span></div>
                    <p></p>
                    <span>
                        {t('P4_CONTEXT')}
                    </span>
                    <p></p>
                    <div id='group3559_button'>
                        <Link to="/register">
                            <button className={'blue_box mx-1 ' } id='p1_b1'>{t('REGISTER_NOW')}</button>
                        </Link>
                        <Link to="/coming_soon">
                            <button className={'tran_box mx-1 ' } id='p1_b1'>{t('INFORMATION')}</button>
                        </Link>
                    </div>
                </div>
                <img className='fluid group3559bg bg' src='./img/home/home_part4_bg.jpg' alt='Change your gameplay forever'/>
            </div>
            <div id='home_end' />
        </div>
    )
}
