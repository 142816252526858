//TODO merge this into single libs for all project, for now version control manually 2023-12-28
// axios.js
import axios from "axios";
import { useCooker } from "./cooker";
import * as AsyncMutex from 'async-mutex';

const apiKey = process.env.REACT_APP_GM_API_KEY;
const refreshTokenMutex = new AsyncMutex.Mutex();
let cooker;



const doRefreshToken = async() => {
  try{
    if(cooker.refreshToken() == null){
      return;
    }

    const response = await put(
      '/auth', 
      JSON.stringify({ 
          refreshtoken: cooker.refreshToken(),
      })
    )

    //check response but error
    console.log("refreshToken res", response);

    // // data: {"uuid": decoded.uuid, accessToken, refreshToken, accessToken_expiration_time, refreshToken_expiration_time},
    const {uuid, accessToken, refreshToken, accessToken_expiration_time, refreshToken_expiration_time} = response.data.data;

    // // Set access token as a cookie
    await cooker.setAccessToken(accessToken, accessToken_expiration_time);
    await cooker.setRefreshToken(refreshToken, refreshToken_expiration_time);
    //email should be the same, no update

  } catch(err) {
      console.log('refreshToken Error:', err);
      cooker.removeAccessToken();
      cooker.removeRefreshToken();
      cooker.removeEmail();
  }finally{
  }
}

export const asyncMutexRefreshToken = async () => {

  if (refreshTokenMutex.isLocked()) {
    //hold subsequence request while the first request still running, then return after the first call is done
    const release = await refreshTokenMutex.acquire();
    return;
  }

  try {
    const release = await refreshTokenMutex.acquire();
    await doRefreshToken();
  } catch (error) {
    console.error('RefreshToken Error:', error);
  } finally {
    refreshTokenMutex.release();
  }
};

export const useGMillionAPI = () => {
  cooker = useCooker();
  axios.defaults.baseURL = process.env.REACT_APP_GM_API_BASEURL;
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    (config) => {
      const token = cooker.accessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${cooker.accessToken()}`;  // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Set up an interceptor to refresh the token before it expires
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      
      if ((error.config.url != '/auth' && originalRequest.method === 'post')       //dont retry on auth path prevent auto refresh lock
        && error.response?.status === 401     //do retry only on 401 response
        && !originalRequest._retry            //dont retry more than once
        && (cooker.refreshToken() != null))   //dont retry when refreshtoken is none
      {
        originalRequest._retry = true;

        // Refresh the access token using the refresh token
        await asyncMutexRefreshToken();

        if (cooker.accessToken()) {
          originalRequest.headers['Authorization'] = `Bearer ${cooker.accessToken()}`;
          return axios(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );
}

export async function post(path, body, headers) {
  // init();
  return await axios.post(
    path,
    body,
    {
      headers: 
      process.env.REACT_APP_ENV === 'dev' ?
        { 
          "Content-Type": "application/json",
          "X-Api-Key": apiKey, 
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
        } :
        { 
          "Content-Type": "application/json",
          // "X-Api-Key": apiKey, //remove this since we use redirect by cloud front
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
        } 
      ,
      withCredentials: false,
    }
  );
}

export async function put(path, body, headers) {
  // init();
  return await axios.put(
    path,
    body,
    {
      headers: 
      process.env.REACT_APP_ENV === 'dev' ?
        { 
          "Content-Type": "application/json",
          "X-Api-Key": apiKey, 
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
        } :
        { 
          "Content-Type": "application/json",
          // "X-Api-Key": apiKey, //remove this since we use redirect by cloud front
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
        } 
      ,
      withCredentials: false,
    }
  );
}

export async function get(path, body, headers) {
  // init();
  console.log('axios.get', apiKey);
  return await axios.get(
    path,
    {
      headers: 
      process.env.REACT_APP_ENV === 'dev' ?
        { 
          "Content-Type": "application/json",
          "X-Api-Key": apiKey, 
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
        } :
        { 
          "Content-Type": "application/json",
          // "X-Api-Key": apiKey, //remove this since we use redirect by cloud front
          // "Authorization": withAccessToken ? `Bearer ${cooker.accessToken()}` : '',
          ...headers,
        } 
      ,
        withCredentials: false,
    },
    body,
  );
}